import { Col, Text, CMSLayout, Row, TouchField, Button, Input, useUIState, Grid, Select } from 'components';
import React, { useState, useEffect, useRef } from 'react';
import { IScreen, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, ScrollView } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons } from '@expo/vector-icons';
import { Image } from 'react-native';
import LogItem from './REAndAdestraSync.LogItem';
import UnsubscirbeFile from './REAndAdestraSync.UnsubscirbeFile';
import Export80File from './REAndAdestraSync.Export80File';
import Export92File from './REAndAdestraSync.Export92File';

const moment = require('moment');

const REAndAdestraSync: IScreen = () => {
  const { navigation } = useNavFunc();
  const [responseData, setResponseData] = useState<any>();
  const [constitunentId, setConstitunentId] = useState('');
  const [{ loading }, setUI] = useUIState();
  const [{ loading: isSubmitting }, setSubmitUI] = useUIState();
  const [errorData, setErrorData] = useState<any>();
  const [tab, setTab] = useState<'re-to-adestra' | 'adestra-unsubscribe-export' | 'adestra-export-80' | 'adestra-export-92'>('re-to-adestra');
  const [tabRE2Adestra, setTabRE2Adestr] = useState<'single' | 'by-day' | 'list-log'>('single');

  const [date, setDate] = useState('');
  const configId = useRef();
  const configAdestraUnsubscribeId = useRef();
  const configAdestraExport80 = useRef();
  const configAdestraExport92 = useRef();
  const [syncConfig, setSyncConfig] = useState({
    hour: '19',
    targetDate: 'the-day-before',
  });
  const [syncAdestraUnsubscribeConfig, setSyncAdestraUnsubscribeConfig] = useState({
    hour: '2',
    targetDate: 'the-day-before',
  });
  const [syncAdestraExport80Config, setSyncAdestraExport80Config] = useState({
    hour: '4',
    targetDate: 'the-day-before',
  });
  const [syncAdestraExport92Config, setSyncAdestraExport92Config] = useState({
    hour: '6',
    targetDate: 'the-day-before',
  });
  const [pickDateResponse, setPickDateResponse] = useState();

  const RE_TO_ADESTRA_TABS = [
    { key: 'single', label: 'Manually check by Constitunent ID' },
    { key: 'by-day', label: 'Manually sync by picking a date' },
    { key: 'list-log', label: 'Execution logs' },
  ]

  const TABS = [
    { key: 're-to-adestra', label: 'RE to Adestra' },
    { key: 'adestra-unsubscribe-export', label: 'Adestra unsubscribe sync' },
    { key: 'adestra-export-80', label: 'Adestra sync Export 80' },
    { key: 'adestra-export-92', label: 'Adestra sync Export 92' },
  ]
  const targetDateOptions = [
    { label: 'The day before', value: 'the-day-before' },
    { label: 'The current day', value: 'the-current-day' },
  ];

  const [logs, setLogs] = useState([]);
  const [logsUnsubscribe, setLogsUnsubscribe] = useState([]);
  const [unsubscribeFiles, setUnsubscribeFiles] = useState([]);

  const [logsExport80, setLogsExport80] = useState([]);
  const [export80Files, setExport80Files] = useState([]);

  const [logsExport92, setLogsExport92] = useState([]);
  const [export92Files, setExport92Files] = useState([]);

  useEffect(() => {
    if (tabRE2Adestra === 'list-log') {
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/raiser-edge-sync/list');
        const json = await res.json();
        if (json.data) {
          setLogs(json.data);
        }
      })();
      (async () => {
        const res = await Store.Api.Data.list({
          type: 're-sync'
        }, {
          field1: 'RE Sync Config',
          name: 'RE Sync Config',
          page: 1,
          allClients: 0,
        });
        const data = res.data.data[0];
        if (data) {
          configId.current = data.id;
          setSyncConfig(data.data);
        }
      })();
      return;
    }
    if (tab === 'adestra-unsubscribe-export') {
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/list-handle-unsubscribe');
        const json = await res.json();
        if (json.data) {
          const data = json.data[0];
          console.log('data.data.listAlreadyProcess', data.data.listAlreadyProcess);
          setLogsUnsubscribe(data.data.listAlreadyProcess);
        }
      })();
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/list-file-unsubscribe');
        const json = await res.json();
        if (json.data) {
          setUnsubscribeFiles(json.data.reverse());
        }
      })();
      (async () => {
        const res = await Store.Api.Data.list({
          type: 'adestra-unsubscribe-sync'
        }, {
          field1: 'Adestra Unsubscribe Sync Config',
          name: 'Adestra Unsubscribe Sync Config',
          page: 1,
          allClients: 0,
        });
        const data = res.data.data[0];
        if (data) {
          configAdestraUnsubscribeId.current = data.id;
          setSyncAdestraUnsubscribeConfig(data.data);
        }
      })();
      return;
    }

    if (tab === 'adestra-export-80') {
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/list-handle-export80');
        const json = await res.json();
        if (json.data) {
          const data = json.data[0];
          console.log('data.data.listAlreadyProcess', data.data.listAlreadyProcess);
          setLogsExport80(data.data.listAlreadyProcess);
        }
      })();
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/list-file-export80');
        const json = await res.json();
        if (json.data) {
          setExport80Files(json.data.reverse());
        }
      })();
      (async () => {
        const res = await Store.Api.Data.list({
          type: 'adestra-export80-sync'
        }, {
          field1: 'Adestra Export80 Sync Config',
          name: 'Adestra Export80 Sync Config',
          page: 1,
          allClients: 0,
        });
        const data = res.data.data[0];
        if (data) {
          configAdestraExport80.current = data.id;
          setSyncAdestraExport80Config(data.data);
        }
      })();
      return;
    }

    if (tab === 'adestra-export-92') {
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/list-handle-export92');
        const json = await res.json();
        if (json.data) {
          const data = json.data[0];
          console.log('data.data.listAlreadyProcess', data.data.listAlreadyProcess);
          setLogsExport92(data.data.listAlreadyProcess);
        }
      })();
      (async () => {
        const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/list-file-export92');
        const json = await res.json();
        if (json.data) {
          setExport92Files(json.data.reverse());
        }
      })();
      (async () => {
        const res = await Store.Api.Data.list({
          type: 'adestra-export92-sync'
        }, {
          field1: 'Adestra Export92 Sync Config',
          name: 'Adestra Export92 Sync Config',
          page: 1,
          allClients: 0,
        });
        const data = res.data.data[0];
        if (data) {
          configAdestraExport92.current = data.id;
          setSyncAdestraExport92Config(data.data);
        }
      })();
      return;
    }
  }, [tab, tabRE2Adestra]);

  const saveSyncConfig = async () => {
    console.log('syncConfig', syncConfig);
    const res = await Store.Api.Data.upsert({
      type: 're-sync'
    }, {
      id: configId.current,
      field1: 'RE Sync Config',
      name: 'RE Sync Config',
      data: syncConfig,
      publicPermission: {
        c: false,
        r: true,
        u: true,
        d: false,
      },
    });
    if (res.data.success) {
      alert('Sync config saved');
    }
  }

  const saveSyncAdestraUnsubscribeConfig = async () => {
    console.log('syncAdestraUnsubscribeConfig', syncAdestraUnsubscribeConfig);
    const res = await Store.Api.Data.upsert({
      type: 'adestra-unsubscribe-sync'
    }, {
      id: configAdestraUnsubscribeId.current,
      field1: 'Adestra Unsubscribe Sync Config',
      name: 'Adestra Unsubscribe Sync Config',
      data: syncAdestraUnsubscribeConfig,
      publicPermission: {
        c: false,
        r: true,
        u: true,
        d: false,
      },
    });
    if (res.data.success) {
      alert('Sync config saved');
    }
  }

  const saveSyncAdestraExport80Config = async () => {
    console.log('saveSyncAdestraExport80Config', syncAdestraExport80Config);
    const res = await Store.Api.Data.upsert({
      type: 'adestra-export80-sync'
    }, {
      id: configAdestraExport80.current,
      field1: 'Adestra Export80 Sync Config',
      name: 'Adestra Export80 Sync Config',
      data: syncAdestraExport80Config,
      publicPermission: {
        c: false,
        r: true,
        u: true,
        d: false,
      },
    });
    if (res.data.success) {
      alert('Sync config saved');
    }
  }

  const saveSyncAdestraExport92Config = async () => {
    console.log('saveSyncAdestraExport92Config', syncAdestraExport92Config);
    const res = await Store.Api.Data.upsert({
      type: 'adestra-export92-sync'
    }, {
      id: configAdestraExport92.current,
      field1: 'Adestra Export92 Sync Config',
      name: 'Adestra Export92 Sync Config',
      data: syncAdestraExport92Config,
      publicPermission: {
        c: false,
        r: true,
        u: true,
        d: false,
      },
    });
    if (res.data.success) {
      alert('Sync config saved');
    }
  }

  const renderListLogs = () => {
    return (
      <Col m2>
        <Col backgroundColor={'white'} p1>
          <Col m0 p0 mb1>
            <Text bold>RE Sync Configuration</Text>
          </Col>
          <Col width={250} p1>
            <Col>
              <Text mb0>Hour (24h format)</Text>
              <Input noOutline mb1 value={String(syncConfig.hour)} onChange={(c) => setSyncConfig({ ...syncConfig, hour: c })} height={40} borderRadius={8} borderColor={'black'} />

              <Text mb0>Target date</Text>
              <Select
                options={targetDateOptions}
                value={targetDateOptions.find(v => v.value === syncConfig.targetDate)}
                onChange={(d) => setSyncConfig({ ...syncConfig, targetDate: d.value })}
                mb2
              />

              <Button
                text='Save'
                width={230}
                borderRadius={8}
                onPress={saveSyncConfig}
              />
            </Col>
          </Col>
        </Col>
        {logs.length > 0 && (
          <Col backgroundColor={'white'} p1>
            <Col m0 p0 mb1>
              <Text bold>List log of executions</Text>
            </Col>
            <Row p0>
              <Col m0 p0 flex1>
                <Text bold>Timestamp</Text>
              </Col>
              <Col m0 p0 flex1>
                <Text bold>Target date</Text>
              </Col>
              <Col m0 p0 flex={3}>
                <Text bold>Results</Text>
              </Col>
            </Row>
            {logs.length === 0 ? (
              <Text m2>Please wait...</Text>
            ) : logs.map((l, li) => {
              return (
                <LogItem key={'log-' + li} {...l} />
              )
              // return (
              //   <Row p0 key={'log-'+li}>
              //     <Col m0 p0 flex1>
              //       <Text>{moment(l.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
              //     </Col>
              //     <Col m0 p0 flex1>
              //       <Text>{l.field1}</Text>
              //     </Col>
              //     <Col m0 p0 flex={3}>
              //       <Text>Results: {l.data.results.length} | Erros: {l.data.errors.length} | OptIn: {l.data.optInList.length} | OptOut: {l.data.optOutList.length}</Text>
              //     </Col>
              //   </Row>
              // )
            })}
          </Col>
        )}
      </Col>
    );
  }

  const renderAdestraUnsubscribeExport = () => {
    return (
      <Col m2>
        <Col backgroundColor={'white'} p1>
          <Col m0 p0 mb1>
            <Text bold>Adestra unsubscribe Sync RE Configuration</Text>
            <Text caption>from <a href="https://app.adestra.com/Tommys/workspace/1/data/export/144/view" target='_blank'>https://app.adestra.com/Tommys/workspace/1/data/export/144/view</a></Text>
          </Col>
          <Col width={250} p1>
            <Col>
              <Text mb0>Hour (24h format)</Text>
              <Input noOutline mb1 value={String(syncAdestraUnsubscribeConfig.hour)} onChange={(c) => setSyncAdestraUnsubscribeConfig({ ...syncAdestraUnsubscribeConfig, hour: c })} height={40} borderRadius={8} borderColor={'black'} />

              {/* <Text mb0>Target date</Text>
              <Select
                options={targetDateOptions}
                value={targetDateOptions.find(v => v.value === syncAdestraUnsubscribeConfig.targetDate)}
                onChange={(d) => setSyncConfig({ ...syncAdestraUnsubscribeConfig, targetDate: d.value })}
                mb2
              /> */}

              <Button
                text='Save'
                width={230}
                borderRadius={8}
                onPress={saveSyncAdestraUnsubscribeConfig}
              />
            </Col>
          </Col>
        </Col>
        {unsubscribeFiles.length > 0 && (
          <Col backgroundColor={'white'} p1>
            <Col m0 p0 mb1>
              <Text bold>List of exported unsubscribe file from Adestra</Text>
            </Col>
            <Row p0>
              <Col m0 p0 flex1>
                <Text bold>File name</Text>
              </Col>
              <Col m0 p0 flex={1}>
                <Text bold>Status</Text>
              </Col>
            </Row>
            {unsubscribeFiles.map((l, li) => {
              const result = logsUnsubscribe.find(v => v.file === l);
              return (
                <UnsubscirbeFile
                  key={'file-' + li}
                  name={l}
                  result={result}
                  onListUpdated={(newLog) => {
                    console.log('onListUpdated', newLog)
                    const data = newLog.data;
                    console.log('data.listAlreadyProcess', data.listAlreadyProcess);
                    setLogsUnsubscribe(data.listAlreadyProcess);
                  }}
                />
              )
              // return (
              //   <Row p0 key={'log-'+li}>
              //     <Col m0 p0 flex1>
              //       <Text>{moment(l.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
              //     </Col>
              //     <Col m0 p0 flex1>
              //       <Text>{l.field1}</Text>
              //     </Col>
              //     <Col m0 p0 flex={3}>
              //       <Text>Results: {l.data.results.length} | Erros: {l.data.errors.length} | OptIn: {l.data.optInList.length} | OptOut: {l.data.optOutList.length}</Text>
              //     </Col>
              //   </Row>
              // )
            })}
          </Col>
        )}
      </Col>
    );
  }

  const renderAdestraExport80 = () => {
    return (
      <Col m2>
        <Col backgroundColor={'white'} p1>
          <Col m0 p0 mb1>
            <Text bold>Adestra Export80 Sync RE Configuration</Text>
            <Text caption>from <a href="https://app.adestra.com/Tommys/workspace/1/data/export/138/view" target='_blank'>https://app.adestra.com/Tommys/workspace/1/data/export/138/view</a></Text>
          </Col>
          <Col width={250} p1>
            <Col>
              <Text mb0>Hour (24h format)</Text>
              <Input noOutline mb1 value={String(syncAdestraExport80Config.hour)} onChange={(c) => setSyncAdestraExport80Config({ ...syncAdestraExport80Config, hour: c })} height={40} borderRadius={8} borderColor={'black'} />

              {/* <Text mb0>Target date</Text>
              <Select
                options={targetDateOptions}
                value={targetDateOptions.find(v => v.value === syncAdestraUnsubscribeConfig.targetDate)}
                onChange={(d) => setSyncConfig({ ...syncAdestraUnsubscribeConfig, targetDate: d.value })}
                mb2
              /> */}

              <Button
                text='Save'
                width={230}
                borderRadius={8}
                onPress={saveSyncAdestraExport80Config}
              />
            </Col>
          </Col>
        </Col>
        {export80Files.length > 0 && (
          <Col backgroundColor={'white'} p1>
            <Col m0 p0 mb1>
              <Text bold>List of exported from export80 file from Adestra</Text>
            </Col>
            <Row p0>
              <Col m0 p0 flex1>
                <Text bold>File name</Text>
              </Col>
              <Col m0 p0 flex={1}>
                <Text bold>Status</Text>
              </Col>
            </Row>
            {export80Files.map((l, li) => {
              const result = logsExport80.find(v => v.file === l && v.status === 'success');
              return (
                <Export80File
                  key={'file-' + li}
                  name={l}
                  result={result}
                  onListUpdated={(newLog) => {
                    console.log('onListUpdated', newLog)
                    const data = newLog.data;
                    console.log('data.listAlreadyProcess', data.listAlreadyProcess);
                    setLogsExport80(data.listAlreadyProcess);
                  }}
                />
              )
              // return (
              //   <Row p0 key={'log-'+li}>
              //     <Col m0 p0 flex1>
              //       <Text>{moment(l.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
              //     </Col>
              //     <Col m0 p0 flex1>
              //       <Text>{l.field1}</Text>
              //     </Col>
              //     <Col m0 p0 flex={3}>
              //       <Text>Results: {l.data.results.length} | Erros: {l.data.errors.length} | OptIn: {l.data.optInList.length} | OptOut: {l.data.optOutList.length}</Text>
              //     </Col>
              //   </Row>
              // )
            })}
          </Col>
        )}
      </Col>
    );
  }

  const renderAdestraExport92 = () => {
    return (
      <Col m2>
        <Col backgroundColor={'white'} p1>
          <Col m0 p0 mb1>
            <Text bold>Adestra Export92 Sync RE Configuration</Text>
            <Text caption>from <a href="https://app.adestra.com/Tommys/workspace/1/data/export/141/view" target='_blank'>https://app.adestra.com/Tommys/workspace/1/data/export/141/view</a></Text>
          </Col>
          <Col width={250} p1>
            <Col>
              <Text mb0>Hour (24h format)</Text>
              <Input noOutline mb1 value={String(syncAdestraExport92Config.hour)} onChange={(c) => setSyncAdestraExport92Config({ ...syncAdestraExport92Config, hour: c })} height={40} borderRadius={8} borderColor={'black'} />

              {/* <Text mb0>Target date</Text>
              <Select
                options={targetDateOptions}
                value={targetDateOptions.find(v => v.value === syncAdestraUnsubscribeConfig.targetDate)}
                onChange={(d) => setSyncConfig({ ...syncAdestraUnsubscribeConfig, targetDate: d.value })}
                mb2
              /> */}

              <Button
                text='Save'
                width={230}
                borderRadius={8}
                onPress={saveSyncAdestraExport92Config}
              />
            </Col>
          </Col>
        </Col>
        {export92Files.length > 0 && (
          <Col backgroundColor={'white'} p1>
            <Col m0 p0 mb1>
              <Text bold>List of exported from export80 file from Adestra</Text>
            </Col>
            <Row p0>
              <Col m0 p0 flex1>
                <Text bold>File name</Text>
              </Col>
              <Col m0 p0 flex={1}>
                <Text bold>Status</Text>
              </Col>
            </Row>
            {export92Files.map((l, li) => {
              const result = logsExport92.find(v => v.file === l && v.status === 'success');
              return (
                <Export92File
                  key={'file-' + li}
                  name={l}
                  result={result}
                  onListUpdated={(newLog) => {
                    console.log('onListUpdated', newLog)
                    const data = newLog.data;
                    console.log('data.listAlreadyProcess', data.listAlreadyProcess);
                    setLogsExport92(data.listAlreadyProcess);
                  }}
                />
              )
              // return (
              //   <Row p0 key={'log-'+li}>
              //     <Col m0 p0 flex1>
              //       <Text>{moment(l.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
              //     </Col>
              //     <Col m0 p0 flex1>
              //       <Text>{l.field1}</Text>
              //     </Col>
              //     <Col m0 p0 flex={3}>
              //       <Text>Results: {l.data.results.length} | Erros: {l.data.errors.length} | OptIn: {l.data.optInList.length} | OptOut: {l.data.optOutList.length}</Text>
              //     </Col>
              //   </Row>
              // )
            })}
          </Col>
        )}
      </Col>
    );
  }

  const renderPickDate = () => {
    return (
      <Col m2>
        <Text mb0>Please fill in the date, format: YYYY-MM-DD</Text>
        <Row mb1>
          <Input maxWidth={270} value={date} onChange={setDate} />
          <Button
            isLoading={loading}
            solid
            ml0
            text='Sync'
            height={48}
            width={100}
            onPress={async () => {
              if (!date) return alert('Please enter the date');
              setUI({ loading: true });
              setErrorData(null);
              setPickDateResponse(null);
              try {
                const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/raiser-edge-sync-list-from-specific-day', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    day: date,
                  })
                });
                const json = await res.json();
                setPickDateResponse(json);
              } catch (err) { }
              setUI({ loading: false });
            }}
          />
        </Row>
        {pickDateResponse && (
          <Col backgroundColor={'white'} p1>
            <Text mb1 bold>Response Data</Text>
            {/* @ts-ignore */}
            <Col round0 backgroundColor={COLOR.GREY_BG} mb2 p1>
              <Text>{JSON.stringify(pickDateResponse, undefined, 4)}</Text>
            </Col>
          </Col>
        )}
      </Col>
    );
  }

  const renderSingle = () => {
    return (
      <Col m2>
        <Text mb0>Please fill in RE Constitunent ID</Text>
        <Row mb1>
          <Input maxWidth={270} value={constitunentId} onChange={setConstitunentId} />
          <Button
            isLoading={loading}
            solid
            ml0
            text='Sync'
            height={48}
            width={100}
            onPress={async () => {
              if (!constitunentId) return alert('Please enter RE constitunent id');
              setUI({ loading: true });
              setErrorData(null);
              setResponseData(null);
              try {
                const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/raiser-edge-sync-one', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({
                    constituent_id: constitunentId,
                  })
                });
                const json = await res.json();
                setResponseData(json);
              } catch (err) { }
              setUI({ loading: false });
            }}
          />
        </Row>
        {responseData && (
          <Col backgroundColor={'white'} p1>
            <Text mb1 bold>Response Data</Text>
            {/* @ts-ignore */}
            <Col round0 backgroundColor={COLOR.GREY_BG} mb2 p1>
              <Text>{JSON.stringify(responseData, undefined, 4)}</Text>
            </Col>
          </Col>
        )}
      </Col>
    );
  }

  return (
    <CMSLayout>
      <Row m1 marginBototm={0} flexWrap={'wrap'}>
        {/* {TABS.map((tab, i) => {
          return (
            <TouchField>

            </TouchField>
          );
        })} */}
        {TABS.map((v, vI) => {
          return (
            <Button
              key={v.key}
              outline={v.key !== tab}
              text={v.label}
              m0
              borderRadius={30} height={30} width={v.label.length * 9}
              onPress={() => setTab(v.key as any)}
            />
          )
        })}
      </Row>
      {tab === 're-to-adestra' && (
        <>
          <Row m1 marginBototm={0} flexWrap={'wrap'}>
            {RE_TO_ADESTRA_TABS.map((v, vI) => {
              return (
                <Button
                  key={v.key}
                  mainColor='rgba(0,0,0,0.8)'
                  outline={v.key !== tabRE2Adestra}
                  text={v.label}
                  m0
                  borderRadius={30} height={30} width={v.label.length * 9}
                  onPress={() => setTabRE2Adestr(v.key as any)}
                />
              )
            })}
          </Row>
          {tabRE2Adestra === 'single'  && renderSingle()}
          {tabRE2Adestra === 'by-day' && renderPickDate()}
          {tabRE2Adestra === 'list-log' && renderListLogs()}
        </>
      )}
      {tab === 'adestra-unsubscribe-export' && renderAdestraUnsubscribeExport()}
      {tab === 'adestra-export-80' && renderAdestraExport80()}
      {tab === 'adestra-export-92' && renderAdestraExport92()}
    </CMSLayout>
  )
};

REAndAdestraSync.routeInfo = {
  title: 'RE and Adestra Sync',
  path: '/re-n-adestra-sync',
};

export default REAndAdestraSync;