import { Col, Text, CMSLayout, Row, TouchField, Button } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { IScreen, TDesign, TPrintJob } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { Ionicons, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { Image } from 'react-native';
import { saveAs } from 'file-saver';

const ListOrder: IScreen = ({ showAllClients, sites }: any) => {
  const { navigation } = useNavFunc();
  // const UserStore = Store.useUserStore();
  const [list, setList] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isLastPageEmpty, setIsLastPageEmpty] = useState(false);

  const [listAllDesignWithProductType, setListAllDesignWithProductType] = useState<Array<TDesign>>([]);

  const getData = async (p = page) => {
    if (p > 1) setIsLoadingMore(true);
    const res = await Store.Api.Data.list({
      type: 'shopify-order-log'
    }, {
      field2: showAllClients ? '' : 'hood',
      userId: '1',
      name: 'order-log',
      allClients: showAllClients ? 1 : 0,
      page: p,
    });
    console.log('res.data', res.data);
    setList(res.data.data);
    setPage(p+1);
    if (res.data.data.length === 0) setIsLastPageEmpty(true);
    if (p > 1) setIsLoadingMore(false);
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    Store.Api.Design.listProductType().then(res => {
      if (res.data.data) {
        setListAllDesignWithProductType(res.data.data);
      }
    });
  }, []);

  const productTypeSKU = useMemo(() => {
    const obj = {};
    listAllDesignWithProductType.forEach((val) => {
      obj[val.sku] = val.productType;
    });
    return obj
  }, [listAllDesignWithProductType]);

  const filterList = useMemo(() => {
    return list.filter(v => !!v.data && !!v.data.line_items && v.data.line_items.length > 0);
  }, [list]);

  const downloadPDF = async (id, lineId) => {
    const res = await Store.Api.Shop.hoodDownloadPDF({ id, lineId });
    if (res.data.error) {
      return alert(res.data.error);
    }
    await saveAs(res.data, `print-file.pdf`);
  }

  const sendHP = async (id) => {
    const res = await Store.Api.Shop.hoodSendHP({ id });
    if (res.data.error) {
      return alert(res.data.error);
    }
    alert('Successfully sent to HP');
  }

  const renderList = (listData = list, withOnPress = true) => {
    return (
      <>
        {withOnPress && (
          <>
            <Row m2 marginBottom={0} justifyContent={'space-between'}>
              <Text>List Orders</Text>
              <Button
                text='Check order'
                outline
                width={120}
                onPress={() => {
                  navigation.navigate(SCREEN.CheckOrder);
                }}
              />
            </Row>
            {/* <TouchField m2 p0 borderRadius={0} mb0 onPress={() => setOnlyCard(!onlyCard)} width={300}>
              <Row>
                <Col
                  width={20} height={20} borderColor={COLOR.MAIN} borderRadius={4} borderWidth={2}
                  backgroundColor={onlyCard ? COLOR.MAIN : 'transparent'}
                />
                <Text ml0>Show only Card order</Text>
              </Row>
            </TouchField> */}
          </>
        )}
        <Col flex1 m2={withOnPress} p2={withOnPress} round1 bgWhite>
          <Row
            height={50} stretch
            borderBottomColor={COLOR.GREY_BG}
            borderBottomWidth={1}
          >
            {!!sites && sites.length > 0 && (
              <Col width={150} m1>
                <Text color={COLOR.GREY}>Client</Text>
              </Col>
            )}
            <Col width={100} m1>
              <Text color={COLOR.GREY}>Timestamp</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Customer Name</Text>
            </Col>
            <Col width={250} m1>
              <Text color={COLOR.GREY}>Order Id / Number / Name</Text>
            </Col>
            <Col flex={2} m1>
              <Text color={COLOR.GREY}>SKU</Text>
            </Col>
            <Col width={200}></Col>
          </Row>

          {filterList.map((val, i) => {
            return (
              <Row
                key={val.id}
                backgroundColor={i % 2 === 0 ? 'rgba(0,0,0,0.05)' : undefined}
                // alignItems={'flex-start'}
              >
                {!!sites && sites.length > 0 && (
                  <Col width={150} m1>
                    <Text>{sites.find(v => v.id === val.siteId)?.name}</Text>
                  </Col>
                )}
                <Col width={100} m1>
                  <Text>{TimeHelper.format(val.data.created_at)}</Text>
                </Col>
                <Col flex1 m1>
                  <Text bold={val.data.status === 'shipped'}>{val.data.customer?.first_name} {val.data.customer?.last_name}</Text>
                </Col>
                <Col width={250} m1>
                  <Text>{val.data.id} / #{val.data.order_number} / {val.data.name}</Text>
                </Col>
                <Col flex={2} m1>
                  {val.data.line_items.map((v, vI) => {
                    const sku = v.sku;
                    const productType = productTypeSKU[sku];
                    return (
                      <Row key={'v'+vI} mb0>
                        <Row mr1 width={150}>
                          {productType ? (
                            <>
                              <Text bold mr1 p0 borderWidth={1} borderColor={'rgba(0,0,0,0.08)'} round0>{productType}</Text>
                              <Text>{sku}</Text>
                            </>
                          ) : (
                            <Text>{sku}</Text>
                          )}
                        </Row>

                        <TouchField mr1 p1 onPress={() => downloadPDF(val.id, v.id)}>
                          <Row>
                            <FontAwesome5 name="file-pdf" size={20} color="black" />
                            <Text ml0>PDF</Text>
                          </Row>
                        </TouchField>
                      </Row>
                    )
                  })}
                </Col>
                <Row width={200}>
                  <TouchField mr1 p1 onPress={() => sendHP(val.id)}>
                    <Row>
                      {val.data.preGeneratedHPItems && val.data.preGeneratedHPItems.length > 0 ? (
                        <MaterialCommunityIcons name="file-check-outline" size={20} color="black" />
                      ) : (
                        <MaterialCommunityIcons name="file-send-outline" size={20} color="black" />
                      )}
                      <Text ml0>Send HP</Text>
                    </Row>
                  </TouchField>
                  <TouchField mr1 p1 onPress={() => {
                    // navigation.navigate(SCREEN.CheckOrder, { id: val.data.id })
                    navigation.reset({
                      index: 0,
                      routes: [{ name: SCREEN.CheckOrder, params: { id: val.data.id } }],
                    });
                  }}>
                    <Text ml0>Detail</Text>
                  </TouchField>
                </Row>
              </Row>
            )
          })}
          {!isLastPageEmpty && page > 1 && (
            <Col middle>
              <TouchField
                onPress={() => {
                  getData(page);
                }}
                borderWidth={1}
                borderColor={COLOR.MAIN}
              >
                <Col width={200} height={40} middle>
                  <Text>{isLoadingMore ? 'Loading more...' : 'Load more'}</Text>
                </Col>
              </TouchField>
            </Col>
          )}
        </Col>
      </>
    )
  }

  return (
    <CMSLayout requireAuthen>
      {renderList()}
    </CMSLayout>
  )
};

ListOrder.routeInfo = {
  title: 'List Orders',
  path: '/orders',
};

export default ListOrder;