import React, { useState } from 'react';
import { Col, Text, Row, TouchField, Button, modal, Select } from 'components';
import { ScrollView, useWindowDimensions } from 'react-native';
const moment = require('moment');
import { Feather } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';
import { COLOR } from 'const';

const DetailModal = (props) => {
  const { width } = useWindowDimensions();
  const [selectedEmail, setSelectedEmail] = useState(undefined);
  const { errors, optInList, optOutList, results } = props.data;
  const options = results.map(v => ({ label: v.email, value: v.email, data: v }));
  return (
    <Col round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7}>
      <Text bold mb2>Details</Text>
      <Select mb2
        value={options.find(o => o.value === selectedEmail)}
        options={options}
        onChange={(newValue) => {
          setSelectedEmail(newValue.value);
        }}
      />
      <ScrollView
        style={{
          width: '100%',
          height: 300,
        }}
      >
        <Row>
          <Col flex1 m0 p0>
            <Text bold>Email</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Category</Text>
          </Col>
          <Col width={70} m0 p0>
            <Text bold>Consent</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Adestra Link</Text>
          </Col>
        </Row>
        {(results || []).filter(v => !selectedEmail ? true : v.email === selectedEmail).map((v, i) => {
          return (
            <Row key={'riww-' + i}>
              <Col flex1 m0 p0>
                <Text>{v.email}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.category}</Text>
              </Col>
              <Col width={70} m0 p0>
                <Text>{v.consent}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Button
                  text='Open Adestra link'
                  iconLeft={<FontAwesome name="external-link" size={18} color="white" />}
                  onPress={() => {
                    window.open(v.adestraLink, '_blank');
                  }}
                />
              </Col>
            </Row>
          )
        })}
      </ScrollView>
    </Col>
  )
}

const UnsubscirbeFile = (props: any) => {
  const [isRunning, setIsRunning] = useState(false);
  const l = props;
  const { result } = l;

  const showModal = () => {
    modal.show(
      <DetailModal
        data={props.data}
      />
    )
  };

  const download = async () => {
    window.open(`https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/download-export/${l.name}`);
  };

  const isProcessed = !!result;

  const run = async () => {
    setIsRunning(true);
    try {
      const res = await fetch('https://hpapi.personify.tech/tommys/api/sites/tommys/adestra-sync/sync-single-unsubscribe-file', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: l.name,
        }),
      });
      const json = await res.json();
      console.log('json', json);
      if (json.data) {
        props.onListUpdated && props.onListUpdated(json.data);
      }
    } catch(err) {

    }
    setIsRunning(false);
  };

  return (
    <Row p0>
      <Row m0 p0 flex1>
        <Text>{l.name}</Text>
        <TouchField cirle ml1 middle onPress={download}>
          <Feather name="download" size={16} color="black" />
        </TouchField>
      </Row>
      <Row m0 p0 flex={1}>
        {!isProcessed ? (
          <>
            <Text caption mr1>Unprocessed</Text>
            {/* <TouchField cirle middle>
              <FontAwesome name="play" size={16} color="black" />
            </TouchField> */}
            <Button
              text='Run'
              isLoading={isRunning}
              iconLeft={(
                <FontAwesome name="play" size={12} color={COLOR.MAIN} />
              )}
              outline
              width={100}
              height={30}
              bgHovered={COLOR.MAIN + '60'}
              onPress={run}
            />
          </>
        ) : (
          <>
            <Text caption>Done at {moment(result.timestamp).format('DD/MM/YYYY HH:mm')}</Text>
          </>
        )}
      </Row>
    </Row>
  );
}

export default UnsubscirbeFile;