import React, { useState } from 'react';
import { Col, Text, Row, TouchField, Button, modal, Select } from 'components';
import { FontAwesome } from '@expo/vector-icons';
import { ScrollView, useWindowDimensions } from 'react-native';
const moment = require('moment');

const DetailModal = (props) => {
  const { width } = useWindowDimensions();
  const [selectedEmail, setSelectedEmail] = useState(undefined);
  const { errors, optInList, optOutList, results } = props.data;
  const options = results.map(v => ({ label: v.email, value: v.email, data: v }));
  return (
    <Col round1 bgWhite shadow p2 width={width < 480 ? 450 : width * 0.7}>
      <Text bold mb2>Details</Text>
      <Select mb2
        value={options.find(o => o.value === selectedEmail)}
        options={options}
        onChange={(newValue) => {
          setSelectedEmail(newValue.value);
        }}
      />
      <ScrollView
        style={{
          width: '100%',
          height: 300,
        }}
      >
        <Row>
          <Col flex1 m0 p0>
            <Text bold>Email</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Category</Text>
          </Col>
          <Col width={70} m0 p0>
            <Text bold>Consent</Text>
          </Col>
          <Col flex1 m0 p0>
            <Text bold>Adestra Link</Text>
          </Col>
        </Row>
        {(results || []).filter(v => !selectedEmail ? true : v.email === selectedEmail).map((v, i) => {
          return (
            <Row key={'riww-' + i}>
              <Col flex1 m0 p0>
                <Text>{v.email}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Text>{v.category}</Text>
              </Col>
              <Col width={70} m0 p0>
                <Text>{v.consent}</Text>
              </Col>
              <Col flex1 m0 p0>
                <Button
                  text='Open Adestra link'
                  iconLeft={<FontAwesome name="external-link" size={18} color="white" />}
                  onPress={() => {
                    window.open(v.adestraLink, '_blank');
                  }}
                />
              </Col>
            </Row>
          )
        })}
      </ScrollView>
    </Col>
  )
}

const LogItem = (props: any) => {
  const l = props;

  const showModal = () => {
    modal.show(
      <DetailModal
        data={props.data}
      />
    )
  };

  return (
    <Row p0>
      <Col m0 p0 flex1>
        <Text>{moment(l.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
      </Col>
      <Col m0 p0 flex1>
        <Text>{l.field1}</Text>
      </Col>
      <Row m0 p0 flex={3}>
        <Text mr1>Results: {l.data.results.length} | Erros: {l.data.errors.length} | OptIn: {l.data.optInList.length} | OptOut: {l.data.optOutList.length}</Text>
        <Button
          text='View detail'
          iconLeft={<FontAwesome name="list" size={12} color="white" />}
          onPress={showModal}
        />
      </Row>
    </Row>
  );
}

export default LogItem;